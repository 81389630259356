/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-18 18:46:44
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-24 15:51:49
 */
import ChartContainer from '../modules/chartContainer'
import RoundPieChart from '../modules/roundPieChart'
import NoData from '../modules/noData'

export default {
  name: 'DeviceSystem',
  components: {
    ChartContainer,
    RoundPieChart,
    NoData
  },
  data () {
    return {
      colors: [
        {
          color: 'rgba(201, 67, 243, 1)',
          endColor: 'rgba(255, 114, 206, 1)'
        },
        {
          color: 'rgba(71, 189, 255, 1)',
          endColor: 'rgba(44, 104, 255, 1)'
        }
      ]
    }
  },
  props: {
    data: {
      type: Array,
      default: () => ([])
    }
  },
  computed: {
    chartData () {
      const self = this
      if (!Array.isArray(self.data) || self.data.length === 0) {
        return []
      }
      return self.data.reduce((res, { label_name: name, user_count: count, total }, index) => {
        res = res.concat({
          name,
          ...self.colors[index],
          value: Math.round(count * 100 / total)
        })
        return res
      }, [])
    }
  }
}
