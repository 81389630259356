/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-18 18:47:13
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-18 19:12:29
 */
import DeviceSystem from './deviceSystem.vue'
export default DeviceSystem
