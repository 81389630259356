/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-18 19:38:02
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-19 11:01:12
 */
import roundPieChart from './roundPieChart.vue'
export default roundPieChart
